import React, { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { initializeAnalytics, setUserId, setUserProperties, setNoDataCollection, logPageView } from './analytics';
import './App.css';
import { ErrorProvider } from './contexts/ErrorContext';
import { HeaderProvider } from './contexts/HeaderContext';
import ErrorNotification from './components/ErrorNotification';
import VideosPage from './pages/VideosPage';
import VideoPage from './pages/VideoPage';
import StudyPlanPage from './pages/StudyPlanPage';
import SettingsPage from './pages/SettingsPage';
import ProfilePage from './pages/ProfilePage';
import LoginPage from './pages/LoginPage';
import yted from './apis/yted';
import Header from './components/Header';

async function getStudyPlans() {
  try {
    const response = await yted.get('/lesson_plan/');
    if (response.status === 200) {
      return response.data;
    }
    else {
      console.error('Failed to fetch study plans');
      return null;
    }
  }
  catch (error) {
    console.error('Failed to fetch study plans', error);
    return null;
  }
}

async function getChildProfile() {
  try {
    const response = await yted.get('/child/profile', {
      timeout: 5000,
    });
    if (response.status === 200) {
      return response.data;
    }
    else {
      console.error('Failed to fetch child profile');
      return null;
    }
  }
  catch (error) {
    console.error('Failed to fetch child profile', error);
    return null;
  }
}

function setUpAnalytics(profile) {
  if (profile.data && profile.data.collect_usage_data) {
    setUserId(profile.username);
    setUserProperties({
      userProfileId: profile.user_profile_id,
      gradeLevel: profile.grade_level,
    });
  } else {
    setNoDataCollection();
  }
}

function usePageTracking() {
  const location = useLocation();

  useEffect(() => {
    logPageView(location.pathname);
  }, [location]);
}

function App() {
  const [profile, setProfile] = useState(null);
  const [user, setUser] = useState(null);
  const [score, setScore] = useState(0);
  const [studyPlans, setStudyPlans] = useState([]);
  const [subscriptionAction, setSubscriptionAction] = useState({action: 'none'});

  const navigate = useNavigate();

  const fetchAndSetProfile = async (updateProfile = true) => {
    const profileData = await getChildProfile();
    if (profileData && profileData.profile) {
      if (updateProfile)
        setProfile(profileData.profile);

      setUser(profileData.user);
      setScore(profileData.score);
    }
  };

  const setSettingsProfile = (profile) => {
    if (profile) {
      setProfile(profile);
    } else {
      setProfile(null);
      setUser(null);
      setScore(0);
    }
  };

  const setupAnalyticsWithProfile = () => {
    if (profile) {
      setUpAnalytics(profile);
    }
  };

  const fetchAndSetStudyPlans = async () => {
    const sps = await getStudyPlans();
    if (sps) {
      setStudyPlans(sps.study_plans);
      setSubscriptionAction(sps.subscription_action);
    }
  };

  const onStudyPlan = (studyPlanId) => {
    navigate(`/feed?sp=${studyPlanId}`);
  };

  const onChildProfile = () => {
    navigate('/settings');
  };

  useEffect(() => {
    initializeAnalytics();
    fetchAndSetProfile();
  }, []);

  useEffect(() => {
    if (profile) {
      setupAnalyticsWithProfile();
      fetchAndSetStudyPlans();
    }
  }, [profile]);

  usePageTracking();

  return (
    <div className="App flex flex-col h-screen">
      <HeaderProvider>
        <Header
          childProfile={profile}
          score={score}
          studyPlans={studyPlans}
          onChildProfile={onChildProfile}
          onStudyPlan={onStudyPlan} />

        <main className="flex-1 p-4 w-full mx-auto max-w-screen-lg">
          <ErrorProvider>
            <Routes>
              <Route path="/" element={
                profile ? (
                  <VideosPage profile={profile} studyPlans={studyPlans} subscriptionAction={subscriptionAction} />
                ) : (
                  <LoginPage fetchProfile={fetchAndSetProfile} />
                )
              } />
              <Route path="/feed" element={
                profile ? (
                  <VideosPage profile={profile} studyPlans={studyPlans} subscriptionAction={subscriptionAction} />
                ) : (
                  <LoginPage fetchProfile={fetchAndSetProfile} />
                )
              } />
              <Route path="/video" element={
                profile ? (
                  <VideoPage profile={profile} user={user} updateScore={async () => await fetchAndSetProfile(false)} />
                ) : (
                  <LoginPage fetchProfile={fetchAndSetProfile} />
                )
              } />
              <Route path="/studyPlan/*" element={
                <StudyPlanPage />
              } />
              <Route path="/settings/*" element={
                <SettingsPage 
                  profile={profile} 
                  setProfile={setSettingsProfile} 
                  setStudyPlans={setStudyPlans} 
                  setSubscriptionAction={setSubscriptionAction} 
                />
              } />
              <Route path="/profile/*" element={
                <ProfilePage profile={profile} setProfile={setProfile} />
              } />
            </Routes>
            <ErrorNotification />
          </ErrorProvider>
        </main>

        <footer className="bg-branch text-white text-center p-4 mt-12">
          <p>
            &copy; 2024 Learnforest LLC
          </p>
          <p className="text-xs mt-1">
            <a href="https://learnforest.ai/tos.html" target="_blank" rel="noreferrer">Terms of Service</a>
            <span className="mx-2">|</span> 
            <a href="https://learnforest.ai/privacy.html" target="_blank" rel="noreferrer">Privacy Policy</a>
            <span className="mx-2">|</span> 
            <a href="mailto:support@learnforest.ai">Support</a>
          </p>
        </footer>
      </HeaderProvider>
    </div>
  );
}

export default App;
