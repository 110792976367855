import { InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';

function parseLatex(text) {
    const result = [];
    const stack = [];
    let currentChunk = [];
    let i = 0;

    while (i < text.length) {
        if (text.slice(i, i + 2) === "\\(") {
            if (stack.length === 0) {
                // Save the text before the LaTeX expression
                if (currentChunk.length > 0) {
                    result.push(currentChunk.join(""));
                    currentChunk = [];
                }
            }
            stack.push("\\(");
            currentChunk.push(text.slice(i, i + 2));
            i += 2;
        } else if (text.slice(i, i + 2) === "\\)" && stack.length > 0) {
            currentChunk.push(text.slice(i, i + 2));
            stack.pop();
            if (stack.length === 0) {
                // Save the LaTeX expression
                result.push(currentChunk.join(""));
                currentChunk = [];
            }
            i += 2;
        } else {
            currentChunk.push(text[i]);
            i++;
        }
    }

    // Add any remaining text
    if (currentChunk.length > 0) {
        result.push(currentChunk.join(""));
    }

    return result;
}
const LatexRenderer = ({ text }) => {
    // Split the text with \( ... \) delimiters
    const parts = parseLatex(text);

    return (
        <span>
            {parts.map((part, index) => {
                if (part.startsWith('\\(') && part.endsWith('\\)')) {
                    const latex = part.slice(2, -2); // Remove the \( and \) symbols
                    return <InlineMath key={index} math={latex} />;
                } else {
                    return <span key={index}>{part}</span>;
                }
            })}
        </span>
    );
};

export default LatexRenderer;
