import React from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useTimeOnPage } from '../analytics';
import { useError } from '../contexts/ErrorContext';
import Profile from '../components/Profile';
import ProfileEdit from '../components/ProfileEdit';
import RecentActivities from '../components/RecentActivities';
import yted, { yted_post } from '../apis/yted';

const SettingsPage = ({ profile, setProfile, setStudyPlans, setSubscriptionAction }) => {
    useTimeOnPage('settingsPage');

    const { setError } = useError();

    const navigate = useNavigate();

    const navigateToEditProfile = () => {
        navigate('edit');
    };

    const navigateToProgress = () => {
        navigate('progress');
    };

    const navigateToProfile = () => {
        navigate('');
    };

    const handleCancel = () => {
        navigate('');
    };

    const handleLogout = async () => {
        await yted_post('/auth/logout', { redirect: false }, setError);

        setStudyPlans([]);
        setSubscriptionAction({action: 'none'});
        setProfile(null);

        navigate('/');
    };

    const handleUpdateProfile = async (editedProfile) => {
        try {
            const response = await yted.put('/user_profile', editedProfile);
            if (response.status === 200) {
                setProfile(editedProfile);
            } else {
                console.error('Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
        handleCancel();
    };

    return (
        <div>
            <Routes>
                <Route path="" element={
                    <div>
                        <div className="flex justify-end full-w">
                            <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" 
                                    onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="border border-gray-300 bg-white rounded-2xl flex flex-row">
                                <div className="px-4 py-2 text-sm text-white bg-blue-500 rounded-l-2xl">
                                    Profile
                                </div>
                                <div className="px-4 py-2 text-sm text-gray-600 cursor-pointer"
                                    onClick={navigateToProgress}>
                                    Progress
                                </div>
                            </div>
                        </div>
                        <Profile 
                            profile={profile} 
                            setProfile={setProfile}
                            onEditProfile={navigateToEditProfile} 
                            />
                    </div>
                } />
                <Route path="progress" element={
                    <div className="text-left">
                        <div className="flex justify-end full-w">
                            <button className="bg-blue-500 text-white py-2 px-4 rounded mr-2" 
                                    onClick={handleLogout}>
                                Logout
                            </button>
                        </div>
                        <div className="flex justify-center">
                            <div className="border border-gray-300 bg-white rounded-2xl flex flex-row">
                                <div className="px-4 py-2 text-sm text-gray-600 cursor-pointer"
                                    onClick={navigateToProfile}>
                                    Profile
                                </div>
                                <div className="px-4 py-2 text-sm text-white bg-blue-500 rounded-r-2xl">
                                    Progress
                                </div>
                            </div>
                        </div>

                        <h1 className="text-xl font-semibold mt-8">Recent activities</h1>

                        {
                            profile && 
                            <RecentActivities username={profile.username} />
                        }
                    </div>
                } />
                <Route path="edit" element={
                    <ProfileEdit profile={profile} onOk={handleUpdateProfile} onCancel={handleCancel} />
                } />
            </Routes>
        </div>
    );
};

export default SettingsPage;
