import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StudyPlanTopic from './StudyPlanTopic';
import yted from '../apis/yted';
import { donutChart } from '../apis/donutChart';

const updateCurrentLessonIndex = async (studyPlanId, lessonIndex, topicIndex) => {
    await yted.post(`/lesson_plan/lesson_index`, {
        study_plan_id: studyPlanId,
        lesson_index: lessonIndex,
        topic_index: topicIndex,
    });
};

const StudyPlanDetail = () => {
    const { studyPlanId } = useParams();

    const [studyPlan, setStudyPlan] = useState(null);
    const [completedVideoIdsDict, setCompletedVideoIdsDict] = useState({});

    const loadStudyPlan = async (planId) => {
        const response = await yted.get(`/lesson_plan/${planId}`);

        setCompletedVideoIdsDict(response.data.completed_video_ids);
        setStudyPlan(response.data.study_plan);
    }

    const updateLessonIndex = (lessonIndex, topicIndex) => {
        const newStudyPlan = { ...studyPlan };
        if (newStudyPlan.current_position) {
            newStudyPlan.current_position.lesson_index = lessonIndex;
            newStudyPlan.current_position.topic_index = topicIndex;
        } else {
            newStudyPlan.current_topic_index = lessonIndex;
        }
        setStudyPlan(newStudyPlan);
    };

    const onLessonClick = async (lessonIndex, doCheck) => {
        const currentLessonIndex = studyPlan.current_position
            ? studyPlan.current_position.lesson_index
            : studyPlan.current_topic_index;
        if (!doCheck || lessonIndex < currentLessonIndex) {
            await updateCurrentLessonIndex(studyPlan.id, lessonIndex, 0);
            updateLessonIndex(lessonIndex, 0);
        }
    };

    const onTopicClick = async (lessonIndex, topicIndex) => {
        const currentLessonIndex = studyPlan.current_position
            ? studyPlan.current_position.lesson_index
            : studyPlan.current_topic_index;
        if (lessonIndex === currentLessonIndex) {
            await updateCurrentLessonIndex(studyPlan.id, lessonIndex, topicIndex);
            updateLessonIndex(lessonIndex, topicIndex);
        }
    };

    useEffect(() => {
        loadStudyPlan(studyPlanId);
    }, []);

    if (!studyPlan) {
        return <div>Loading...</div>;
    }

    return <div className="text-left">
        <h1 className="text-2xl font-bold">{studyPlan.emoji} {studyPlan.subject}</h1>
        <div className="text-lg mb-6">{studyPlan.goal}</div>

        {
        studyPlan && studyPlan.lessons.map((lesson, lessonIndex) => {
            const completedVideosNum = lesson.id in completedVideoIdsDict
                ? completedVideoIdsDict[lesson.id].length : 0;
            const currentLessonIndex = studyPlan.current_position
                ? studyPlan.current_position.lesson_index
                : studyPlan.current_topic_index;
            const currentTopicIndex = studyPlan.current_position
                ? studyPlan.current_position.topic_index
                : 0;

            let lessonColor;
            let boxStyle;
            let arrowColor;
            let nextLessonEnabled;
            let nextLessonStyle;
            let topicType;
            let topicStyle;

            if (lessonIndex < currentLessonIndex) {
                lessonColor = 'text-gray-500';
                boxStyle = 'bg-graygreen text-white cursor-pointer';
                arrowColor = 'border-t-gray-500';
                topicType = 'done';
                topicStyle = '';
            } else if (lessonIndex === currentLessonIndex) {
                lessonColor = 'text-black';
                boxStyle = 'bg-green-600 text-white';
                arrowColor = 'border-t-blue-500';
                topicType = 'current';
                topicStyle = 'cursor-pointer';

                if (lessonIndex < studyPlan.lessons.length - 1) {
                    nextLessonEnabled = completedVideosNum >= lesson.num_videos;
                    nextLessonStyle = nextLessonEnabled ? 'bg-blue-500' : 'bg-gray-400';    
                }
            } else {
                lessonColor = 'text-black';
                boxStyle = 'bg-white border border-black';
                arrowColor = 'border-t-black';
                topicType = 'normal';
                topicStyle = '';
            }

            return <div key={lessonIndex} className={`flex items-start mb-4 ${lessonColor}`}>
                <div className="mr-4 flex flex-col items-center">
                    <div className={`w-12 h-12 ${boxStyle} flex items-center justify-center rounded-md`}
                        onClick={() => onLessonClick(lessonIndex, true)}>
                        {lessonIndex + 1}
                    </div>
                    {
                        lessonIndex < studyPlan.lessons.length - 1 && <div className={`w-12 text-center ${arrowColor}`}>
                            ⇣
                        </div>
                    }
                </div>
                <div>
                    <div className="font-semibold">{lesson.title}</div>
                    <div>{lesson.explanation}</div>

                    {
                        topicType !== 'done' && lesson.topics && lesson.topics.map((topic, topicIndex) => (
                            <div key={`lesson1:${topic.id}`} className={topicStyle} onClick={() => onTopicClick(lessonIndex, topicIndex)}>
                                <StudyPlanTopic topic={topic} index={topicIndex} topicType={
                                    topicType === 'current'
                                        ? (topicIndex <= currentTopicIndex ? 'current' : 'normal')
                                        : topicType
                                } />
                            </div>
                        ))
                    }

                    <div className="flex items-center">
                    {
                        lessonIndex === currentLessonIndex && 
                        <div className="flex items-center mr-3">
                            <button className={`${nextLessonStyle} text-white py-2 px-4 rounded mt-2`}
                                disabled={!nextLessonEnabled}
                                onClick={() => onLessonClick(lessonIndex + 1, false)}>
                                Next lesson
                            </button>
                        </div>
                    }
                    {
                        lessonIndex === currentLessonIndex &&
                        <div className="flex items-center mt-2">
                            { donutChart({ 
                                percentage: Math.min(100, Math.floor(completedVideosNum / lesson.num_videos * 100))
                            }) }
                            <div className="ml-1">{completedVideosNum}/{lesson.num_videos} video(s) completed</div>
                        </div>
                    }
                    </div>
                </div>
            </div>;
        })
        }
    </div>;
};

export default StudyPlanDetail;
