import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import { logEvent } from '../analytics';
import { useError } from '../contexts/ErrorContext';
import Confetti from 'react-confetti';
import VideoQnA from './VideoQnA';
import Speech from './Speech';
import FeedbackButton from './FeedbackButton';
import { yted_get, yted_post } from '../apis/yted';
import { stream_get, stream_post } from '../apis/lfApiFetch';
import { chunkArray } from '../apis/array';
import { parseLines, processText } from '../apis/stream';
import InfoTooltip from './InfoTooltip';
import StarRating from './StarRating';

function _parseTakeaways(text) {
  const takeaway_score_max = 10;
  const takeaway_score_threshold = 8;
  return chunkArray(parseLines(text), 4).map(
    (values) => ({
      takeaway: values[0],
      example: values[1],
      score: values.length < 4 ? takeaway_score_max: parseInt(values[3]),
    })
  ).filter(
    (takeaway) => takeaway.score >= takeaway_score_threshold
  );
}

async function _streamTakeaways(videoId, channelId, studyPlanId, lessonId, topicId) {
  return stream_get('video/takeaways', {
    vid: videoId,
    channel_id: channelId,
    study_plan_id: studyPlanId,
    lesson_id: lessonId,
    topic_id: topicId,
  });
}

async function _streamTakeawaySpeech(videoId) {
  return await stream_post('/video/takeaways/speech', {
    vid: videoId,
  });
}

async function _getQnA(videoId, studyPlanId, setError) {
  return await yted_get(
    '/video/qna',
    {
      vid: videoId,
      study_plan_id: studyPlanId,
    },
    setError,
    60000, // QnA takes a while to generate
  );
}

async function _updateProgress(videoId, channelId, studyPlanId, lessonId, topicId, progress, setError) {
  return yted_post(
    '/video/progress', 
    {
      vid: videoId,
      channel_id: channelId,
      study_plan_id: studyPlanId,
      lesson_id: lessonId,
      topic_id: topicId,
      progress: progress,
    },
    setError,
  );
}

function parseSearchParam() {
  const params = new URLSearchParams(window.location.search);
  const studyPlanId = params.get('spid');
  const lessonId = params.get('lid');
  const topicId = params.get('tid');
  return { studyPlanId, lessonId, topicId };
}

function logNextEvent(videoId, currentState) {
  const { studyPlanId, lessonId, topicId } = parseSearchParam();
  logEvent({
    category: `video_detail_next`,
    action: 'click',
    label: currentState,
    props: { videoId, topicId, lessonId, studyPlanId },
  });

  if (['QNA', 'FIRST_QNA'].includes(currentState)) {
    logEvent({
      category: `quiz`,
      action: 'quiz',
      label: currentState,
      props: { videoId, topicId, lessonId, studyPlanId },
    });
  }
}

function playEarnSound() {
  const audio = new Audio('/sound/earn.wav');
  audio.play().catch(error => {
    console.error('Audio playback failed:', error);
  });
}

function _getAnimal(objectId) {
  const animals = ['dog', 'owl', 'hedgehog'];
  const chatIdSum = objectId.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  return animals[chatIdSum % animals.length];
};

const VideoDetail = ({ profile, user, videoId, channelId }) => {
  const { setError } = useError();
  
  const [videoDetail, setVideoDetail] = useState({
    takeaways: [],
    takeawaysDone: false,
    qna: [],
    qnaDone: false,
  });
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(-1);
  const [showExamples, setShowExamples] = useState(false);

  // Indicates whether the current quiz has been finished by the user
  const [eoc, setEoc] = useState(true);
  const [starCount, setStarCount] = useState(0);
  const [totalStarCount, setTotalStarCount] = useState(0);
  const [displayedStarCount, setDisplayedStarCount] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);
  const audioRef = useRef(null);

  const [bottomVisible, setBottomVisible] = useState(false);
  const bottomRef = useRef(null);

  const { width, height } = useWindowSize();

  const navigate = useNavigate();

  function navigateToFeed() {
    const params = new URLSearchParams(window.location.search);
    const studyPlanId = params.get('spid');
    navigate(`/feed?sp=${studyPlanId}`);
  }

  useEffect(() => {
    if (videoId) {
      loadVideoDetail(videoId);
    }
    if (bottomVisible) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [videoId, bottomVisible]);

  useEffect(() => {
    if (totalStarCount !== displayedStarCount) {
      const difference = totalStarCount - displayedStarCount;
      const increment = difference > 0 ? 1 : -1;
      const timer = setTimeout(() => {
        setDisplayedStarCount(prevCount => prevCount + increment);
      }, 200);
      return () => clearTimeout(timer);
    }
  }, [totalStarCount, displayedStarCount]);

  useEffect(() => {
    if (showConfetti) {
      // Create a new Audio instance each time
      const audio = new Audio('/sound/tada.wav');
      audioRef.current = audio;

      // Attempt to play the audio
      audio.play().catch(error => {
        console.error('Audio playback failed:', error);
      });

      const timer =setTimeout(() => {
        setShowConfetti(false);
        if (audioRef.current) {
          audioRef.current.pause();
          audioRef.current = null;
        }
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);

  if (!videoId) {
    return <div>Loading...</div>;
  }

  const videoSrc = `https://www.youtube.com/embed/${videoId}?rel=0&autoplay=1`;

  async function loadVideoDetail(videoId) {
    if (process.env.REACT_APP_LF_ENABLE_QUIZ !== 'true' && user?.type !== 'premium') {
      setVideoDetail((prev) => ({
        ...prev,
        takeawaysDone: true,
        qnaDone: true,
      }));
      return;
    }

    const { studyPlanId, lessonId, topicId } = parseSearchParam();
    
    try {
      const res = await _streamTakeaways(videoId, channelId, studyPlanId, lessonId, topicId);
      await processText(res, (text) =>
        setVideoDetail((prev) => ({
          ...prev,
          takeaways: _parseTakeaways(text),
        }))
      );
    }
    catch (error) {
      console.error('Error loading video takeaways: ', error);
    }

    setVideoDetail((prev) => ({
      ...prev,
      takeawaysDone: true,
    }));

    const res = await _getQnA(videoId, studyPlanId, setError);
    if (res !== null)
      setVideoDetail((prev) => ({
        ...prev,
        qna: res.data.qna,
      }));

    setVideoDetail((prev) => ({
      ...prev,
      qnaDone: true,
    }));
  }

  function currentState() {
    if (videoDetail === null || !videoDetail.takeawaysDone)
      return 'LOADING';
    else if (selectedComponentIndex === -1 && videoDetail.takeaways.length > 0)
      return 'TAKEAWAYS_HIDDEN';
    else if (!videoDetail.qnaDone)
      return 'TAKEAWAYS_SHOWN';
    else if (videoDetail.qna.length === 0)
      return 'LAST_QNA';
    else if (selectedComponentIndex <= 0)
      return 'FIRST_QNA';
    else if (selectedComponentIndex < videoDetail.qna.length)
      return 'QNA';
    else
      return 'LAST_QNA';
  }

  function currentButtonLabel() {
    switch (currentState()) {
      case 'LOADING':
        return 'Loading quiz...';
      case 'TAKEAWAYS_HIDDEN':
        return 'Take quiz';
      case 'TAKEAWAYS_SHOWN':
        return 'Loading quiz...';
      case 'FIRST_QNA':
        return 'Take quiz';
      case 'QNA':
        return starCount > 0 ? `Claim ${starCount} stars` : 'Next question';
      case 'LAST_QNA':
        return 'Done';
      default:
        return 'Unknown';
    }
  }

  async function onNext() {
    logNextEvent(videoId, currentState());

    const { studyPlanId, lessonId, topicId } = parseSearchParam();

    if (currentState() === 'LAST_QNA') {
      await _updateProgress(videoId, channelId, studyPlanId, lessonId, topicId, 100, setError);
      navigateToFeed();
    }
    else {
      const newVal = selectedComponentIndex + 1;
      setSelectedComponentIndex(newVal);
      if (newVal > 0) {
        if (starCount > 0) {
          setTotalStarCount(prevTotal => prevTotal + starCount);
          playEarnSound();
        }
        setEoc(videoDetail.qna[newVal - 1].eoc);
        setStarCount(0);
        
        await _updateProgress(
          videoId, channelId, studyPlanId, lessonId, topicId,
          Math.round(newVal / videoDetail.qna.length * 100), setError)
      }

      setBottomVisible(true);
    }
  }

  function handleEoc(isEoc, stars, showConfetti) {
    if (selectedComponentIndex === videoDetail.qna.length && showConfetti) {
      setShowConfetti(true);
    }

    setEoc(isEoc);
    setStarCount(stars);

    // In the last question there's no "claim points" button
    if (currentState() === 'LAST_QNA') {
      setTotalStarCount(prevTotal => prevTotal + stars);

      // No need to play the "earn" sound here since 
      // "tada" sound will be played with confetti
    }
  }

  function renderComponent() {
    if (videoDetail === null) {
      return <div>Loading...</div>;
    }

    switch (selectedComponentIndex) {
      case -1:
        return <div></div>

      case 0:
        return <div className="w-full">
          <h4 className="mb-2">
            {
              videoDetail.takeaways.length > 0 
                ? <div>
                    <img src={`/char-${_getAnimal(videoId)}.png`} 
                      alt={_getAnimal(videoId)} 
                      className="inline-block w-12 mr-2" />
                    Key takeaways to study before the quiz:
                    <Speech getSpeech={async () => _streamTakeawaySpeech(videoId)} />
                </div>
                : ""
            }
          </h4>

          {
            <div className="text-right mb-2">
              <div className="text-sm cursor-pointer" onClick={() => setShowExamples(!showExamples)}>
                {showExamples ? 'Show less' : 'Show more'}
              </div>
            </div>
          }

          <ol className="list-decimal list-outside pl-6">
            {
              videoDetail.takeaways.map((item, index) => {
                return <li key={index} className="mb-1">
                  {(() => {
                    const colonIndex = item.takeaway.indexOf(':');
                    if (colonIndex !== -1) {
                      const mainPoint = item.takeaway.slice(0, colonIndex);
                      const explanation = item.takeaway.slice(colonIndex + 1);
                      return (
                        <>
                          <span className="font-semibold">{mainPoint}</span>
                          {':' + explanation}
                        </>
                      );
                    } else {
                      return item.takeaway;
                    }
                  })()}
                  {
                    showExamples &&
                    <div className="mt-1 mb-2 p-2 border border-yellow-500 rounded-lg flex">
                      <span className="mr-1">💡</span>
                      <span>{item.example}</span>
                    </div>
                  }
                </li>
              })
            }
          </ol>

          <div className="flex justify-end">
            <FeedbackButton contentIds={[videoId, channelId]} data={{kind: "takeaway"}} />
          </div>
        </div>

      default:
        if (selectedComponentIndex <= videoDetail.qna.length)
          return <div className="w-full">
            <div className="flex justify-between items-center my-2">
              <div className="font-bold">
                Question {selectedComponentIndex}/{videoDetail.qna.length}:
              </div>
              {totalStarCount > 0 && (
                <div className="inline-block bg-yellow-300 text-black px-2 py-1 rounded-full">
                  ⭐ {displayedStarCount}
                </div>
              )}
            </div>
            <VideoQnA 
              key={selectedComponentIndex} 
              videoId={videoId}
              qna={videoDetail.qna[selectedComponentIndex - 1]}
              onEoc={handleEoc} />
          </div>
        else
            return <div></div>
    }
  }

  return (
    <div className="flex flex-col text-left">
      <div className="p-2 relative">
        <div>
          <iframe
            title="video player"
            src={videoSrc}
            className="w-full aspect-video rounded-xl"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        </div>
        {
          selectedComponentIndex === -1 &&
          <div className="absolute right-0 flex items-center m-2">
            <FeedbackButton contentIds={[videoId, channelId]} />
          </div>
        }
      </div>
      <div className="p-2 w-full md:w-4/5 lg:w-3/5">
        {
          ['LOADING', 'TAKEAWAYS_HIDDEN'].includes(currentState()) &&
          <div className="flex mb-3 items-center">
            <button 
                className={`${['LOADING'].includes(currentState()) ? 'bg-gray-400' : 'bg-blue-500'} text-white py-2 px-4 rounded`}
                disabled={['LOADING'].includes(currentState())}
                onClick={onNext}>
              {currentButtonLabel()}
            </button>
            {
              process.env.REACT_APP_LF_ENABLE_QUIZ === 'true' && <div className="ml-2">
                <InfoTooltip text="Watch the video and pass the quiz to mark as completed" />
              </div>
            }
          </div>
        }

        {renderComponent()}

        {
          ['TAKEAWAYS_SHOWN', 'QNA', 'LAST_QNA', 'FIRST_QNA'].includes(currentState()) &&
          <div className="flex my-6">
            <button 
                className={
                  `${(['TAKEAWAYS_SHOWN'].includes(currentState()) || !eoc)
                    ? 'bg-gray-400' 
                    : 'bg-blue-500'
                  } text-white py-2 px-4 rounded`}
                disabled={['TAKEAWAYS_SHOWN'].includes(currentState()) || !eoc}
                onClick={onNext}>
              {currentButtonLabel()}
            </button>
            <div className="ml-2">
              <StarRating rating={starCount} />
            </div>
          </div>
        }
      </div>
      <div ref={bottomRef}></div>
      {showConfetti && 
        <Confetti width={width} height={height} 
          style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', pointerEvents: 'none' }}
        />
      }
    </div>
  );
};

export default VideoDetail;
