import React, { useState, useEffect, useRef } from 'react';
import yted from '../apis/yted';
import Speech from './Speech';
import AnimatingDot from './AnimatingDot';
import LatexRenderer from './LatexRenderer';
import FeedbackButton from './FeedbackButton';
import { stream_post } from '../apis/lfApiFetch';
import { processText, parseLines } from '../apis/stream';
import { deleteArrayElement } from '../apis/array';

const _checkAnswer = async (qna, userAnswer, multipleChoice) => {
  try {
    return await stream_post('/chat/', {
      chat_id: qna.chat_id,
      user_response: userAnswer,
      multiple_choice: multipleChoice,
    });
  } catch (error) {
    console.error('Error chatting:', error);
    return '';
  }
};

const _streamChatResponse = async (qna) => {
  try {
    return await stream_post('/chat/speech', {
      chat_id: qna.chat_id,
    });
  } catch (error) {
    console.error('Error streaming chat response:', error);
    return '';
  }
};

const _appendHint = async (qna, hint) => {
  try {
    return await yted.post('/chat/hint', {
      chat_id: qna.chat_id,
      hint: hint,
    });
  } catch (error) {
    console.error('Error appending hint:', error);
    return '';
  }
};

const _getAnimal = (objectId) => {
  const animals = ['dog', 'owl', 'hedgehog'];
  const chatIdSum = objectId.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
  return animals[chatIdSum % animals.length];
};

const VideoQnA = ({ videoId, qna, onEoc }) => {
  const [hintIndex, setHintIndex] = useState(0);
  const [isChoicesShown, setIsChoicesShown] = useState(false);
  const [choices, setChoices] = useState(qna.multiple_choices);

  const [userAnswer, setUserAnswer] = useState('');
  const [chatHistory, setChatHistory] = useState(
    qna.messages.map((message) => ({ sender: message.role, text: message.message })));
  const [isAssistantLoading, setIsAssistantLoading] = useState(false);

  const [eoc, setEoc] = useState(qna.eoc);
  const eocTimeoutIdRef = useRef(null);

  function clearEocTimeoutId() {
    if (eocTimeoutIdRef.current) {
      clearTimeout(eocTimeoutIdRef.current);
      eocTimeoutIdRef.current = null;
    }
  }

  async function processAnswer(answer) {
    if (answer.trim() === '' || isAssistantLoading)
      return;

    setIsAssistantLoading(true);
    clearEocTimeoutId();

    const newChatHistory = [...chatHistory, { sender: 'user', text: answer }];
    setChatHistory([...newChatHistory, { sender: 'assistant', text: 'LOADING' }]);
    setUserAnswer('');

    const isMultipleChoice = isChoicesShown;
    let isEoc = false;
    let starCount = 0;
    const result = await _checkAnswer(qna, answer, isMultipleChoice);
    await processText(result, (text) => {
      const lines = parseLines(text);
      if (lines[lines.length - 1].startsWith('EOM')) {
        starCount = parseInt(lines[lines.length - 1].split(' ')[1]);
        isEoc = true;
      } else {
        setChatHistory([...newChatHistory, { sender: 'assistant', text: text }]);
      }
    });

    setIsAssistantLoading(false);

    if (isEoc) {
      setEoc(true);
      onEoc(true, starCount, true);
    } else {
      // Reset EOC mark before setting the timeout handler. 
      // Upon timeout, EOC will be set back to true
      onEoc(false, 0, false);
      eocTimeoutIdRef.current = setTimeout(() => {
        onEoc(true, 0, false);
      }, 10000);
    }
  }
  
  async function handleShowHint() {
    clearEocTimeoutId();
    
    const hint = qna.hints[hintIndex];
    setChatHistory([...chatHistory, { sender: 'assistant', text: hint }]);
    setHintIndex(hintIndex + 1);

    await _appendHint(qna, hint);
  }

  function handleShowChoices() {
    setIsChoicesShown(true);
  }

  async function handleChoice(index) {
    await processAnswer(choices[index]);

    setChoices(deleteArrayElement(choices, index));
  }
  
  function handleAnswerChange(event) {
    clearEocTimeoutId();

    if (event.target.value.indexOf("\n") >= 0) {
      handleSubmit(event);
    }
    else {
      setUserAnswer(event.target.value);
    }
  }

  async function handleSubmit(event) {
    if (event)
      event.preventDefault();

    processAnswer(userAnswer);
  }

  useEffect(() => {
    return () => {
      if (eocTimeoutIdRef.current) {
        clearTimeout(eocTimeoutIdRef.current);
      }
    };
  }, []);

  return (
    <div>
      {chatHistory.map((message, index) => (
        <div key={index} className="mb-3">
        {
          message.sender === 'assistant' && <div className="flex w-full">
            <div className="mr-2 text-xl">
              <img src={`/char-${_getAnimal(videoId)}.png`} alt={_getAnimal(videoId)} className="w-12" />
            </div>
            {
              message.text === 'LOADING' 
              ? <div className="py-1.5">
                  <AnimatingDot />
                </div>
              : <div className="w-full">
                  <div className="inline-block p-2 border border-gray-300 rounded-xl bg-white">
                    <LatexRenderer text={message.text} />
                    {
                      index === chatHistory.length - 1 && isAssistantLoading && <span className="ml-1">
                        <AnimatingDot />
                      </span>
                    }
                    {
                      index === chatHistory.length - 1 && !isAssistantLoading &&
                      <Speech getSpeech={async () => _streamChatResponse(qna)} />
                    }
                  </div>
                </div>
            }
            {
              index === chatHistory.length - 1 && !isAssistantLoading && 
              <div className="flex flex-col justify-end min-w-8 py-1">
                <FeedbackButton contentIds={[qna.chat_id]} data={{chat_index: index}} />
              </div>
            }
          </div>
        }
        {
          message.sender === 'user' && 
          <div className="w-4/5 ml-auto border border-blue-500 p-2 rounded-xl bg-white">
            <LatexRenderer text={message.text} />
          </div>
        }
        </div>
      ))}

      {
        !eoc && isChoicesShown && !isAssistantLoading && choices.map((choice, index) => (
          <div key={index} className="my-1">
            <div onClick={() => handleChoice(index)} 
                className="inline-block cursor-pointer border border-gray-300 bg-white rounded-lg py-1 px-2">
              {choice}
            </div>
          </div>
        ))
      }

      <div className="flex justify-end">
      {
        !eoc && hintIndex < 2 && <button onClick={handleShowHint} className="text-sm">Show hint</button>
      }
      {
        !eoc && !isChoicesShown && <button onClick={handleShowChoices} className="text-sm ml-4">Show choices</button>
      }
      </div>

      {
        !eoc && <form onSubmit={handleSubmit} className="mt-4">
          <textarea
            type="text"
            placeholder="Enter your answer here"
            maxLength={300}
            rows={2}
            value={userAnswer}
            onChange={handleAnswerChange}
            className="w-full p-1 border border-gray-300"
          />
        </form>
      }
    </div>
  );
}

export default VideoQnA;
